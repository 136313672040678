import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Embeds from "../components/embeds"

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 80vh;
  background: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

const Description = styled.div`
  width: calc(100% - 2rem);
  height: 100%;
  max-width: 400px;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const NiceLink = styled(Link)`
  color: inherit;
  font-weight: 600;
  font-family: Kanit;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Embeds />
    <Container>
      <Description>
        <h1>404: Not found</h1>
        <p>
          We don't know where in the Verdn what you're looking for has gone...
        </p>
        <NiceLink to="/">Try finding it from the Home page</NiceLink>
      </Description>
    </Container>
  </Layout>
)

export default NotFoundPage
